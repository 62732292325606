
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://d1bbe4458f884c6ba40890087b9ca2f9@sentry.wixpress.com/5773',
    id: 'undefined',
    projectName: 'appointment-waitlist-form-31',
    teamName: 'bookings',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["test"],"centralized":true};
  const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"waitlist-form.form-dialog-header-title":"Join Waitlist","waitlist-form.form-dialog-header-loading-service-details":"Loading service details...","waitlist-form.form-dialog-header-service-details-multi-service":"{numberOfServices} services","waitlist-form.form-dialog-header-service-details":"{serviceName} | {paymentDescription}","waitlist-form.service.payment.from-price":"From {price}","waitlist-form.form-dialog-success-title":"You've been added to the waitlist","waitlist-form.form-dialog-success-text":"Thanks for joining the waitlist.<br>We will be in touch soon.","waitlist-form.form-dialog-success-button":"Done","waitlist-form.waitlist-notification-text":"Can’t find the date or time you’re looking for? <Link>Join the waitlist</Link>","waitlist-form.settings.tabs.main":"Main","waitlist-form.settings.tabs.design":"Design","waitlist-form.settings.main.description":"Let your clients join the Appointment Waitlist if they can't find an available time they want to book.","waitlist-form.settings.main.title":"Appointment Waitlist","waitlist-form.settings.design.notification-background-color":"Background color & opacity","waitlist-form.settings.design.notification-link-text-color":"Link color","waitlist-form.settings.design.text-font":"Text","waitlist-form.settings.design.font-picker-title":"Choose a font","waitlist-form.settings.design.main-header":"Design","waitlist-form.settings.main.manage-button":"Manage Waitlist"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'appointment-waitlist-form',
    appDefId: '894e612f-f5b5-4641-aa40-696963c689d2',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
